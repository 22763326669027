import React from "react";
import Section from "./Section";
import "../componentCss/SectionContainer.css";

const Magazine = (props) => {
  return (
    <Section id="magazine" lightDark={props.lightDark} backgroundSet={false}>
      <h1 style={{ color: "white", margin: "50px" }}>E-levate Magazine</h1>
      <div className="sectionContainer" style={{ color: "white" }}>
        <div className="magazine-container">
          <div
            className="magazine-sub-container bg-gradient"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <h2 className="h-magazine">
              <span style={{ fontSize: "2rem" }}>What</span> is E-levate?
            </h2>
            <p>
              <span>E</span>-levate is a digital magazine published by EnB cell
              that provides valuable information and inspiration for
              entrepreneurs. The magazine features interviews with successful
              entrepreneurs, case studies of successful businesses, and articles
              on a variety of topics such as marketing, finance, and leadership.
              It also covers the latest happenings in the market, providing
              readers with up-to-date insights and ideas.
            </p>
          </div>
        </div>
        <div className="magazine-container">
          <div
            className="magazine-sub-container bg-gradient"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <h2 className="h-magazine">
              <span style={{ fontSize: "2rem" }}>Why</span> should you read
              E-levate?
            </h2>
            <p>
              E-levate is a valuable resource for anyone interested in
              entrepreneurship or business. By reading this magazine, you can
              stay up-to-date on the latest trends and best practices in
              different industries, and gain insights that you can apply to your
              own business. In addition, reading E-levate can provide students
              with opportunities to engage with the business community and build
              their own networks.
            </p>
          </div>
        </div>
        <div className="magazine-container">
          <div
            className="magazine-sub-container bg-gradient"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <h2 className="h-magazine">Want to feature in E-levate?</h2>
            <p>
              If you're a student and you have findings, experiences, case
              studies or interesting topics related to business that you want to
              share, you can reach us through email. We will be more than happy
              to publish it under your name. However, please note that
              plagiarized content will not be published in the magazine.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Magazine;
